import React, { Component } from 'react';

export class Home extends Component {
  static displayName = Home.name;

  render() {
    return (
      <div>
        <h1>Hello, Welcome to Poroshtica Family Business !</h1>
          <p>Please be aware this site is only for invide only!</p>
      </div>
    );
  }
}
